import { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  createContract,
  getNegotiationById,
  getNegotiationRoles,
  updateNegotiation
} from 'src/api';
import { navbarColorAtom, userAtom } from 'src/atoms';
import { AlertModal } from 'src/components/core/AlertModal';
import { LoadScreen } from 'src/components/core/LoadScreen';
import { Answer, Negotiation as INegotiation } from 'src/interfaces';
import { NegotiationPanel } from '../components/negotiation/NegotiationPanel';
import { ContractType } from '../interfaces';
import { parseConditions } from 'src/utils/parseConditions';
import { parseAnswers } from 'src/utils/parseAnswers';
import _ from 'lodash';
import { negotiationPanelTheme } from 'src/theme/theme';
import {
  getStripeUrl
} from 'src/api/payment';
import { useApiHelpers } from 'src/hooks/useApiHelpers';
import { openInCurrentTab } from 'src/utils/openInTabHelper';

export const Negotiation = () => {
  const [negotiation, setNegotiation] = useState<INegotiation | null>();
  const [, setNavbarColor] = useAtom(navbarColorAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isOpen: isOpenSent, onClose: onCloseSent, onOpen: onOpenSent } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenError,
    onClose: onCloseError,
    onOpen: onOpenError
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingContract, setIsGeneratingContract] =
    useState<boolean>(false);

  const [params] = useSearchParams();
  const [isFirstCounter, setIsFirstCounter] = useState<boolean>(false);
  const hasCounterParam = !!params.get('first-counter');
  const paymentParam = params.get('payment');
  const roleRetrieved: any = useRef(null);
  const [firstCounterAnswers, setFirstCounterAnswers] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [user] = useAtom(userAtom);

  //Check if this is the first response
  useEffect(() => {
    const setRole = async () => {
      if (
        hasCounterParam &&
        negotiation &&
        negotiation.receiverResponses.length === 0 &&
        roleRetrieved.current === null
      ) {
        const { data } = await getNegotiationRoles(negotiation._id);

        /**
         * Set answers to hidden questions
         */
        const answers = [
          {
            stepId: 'role',
            subStepId: 'role',
            questionId: 'role',
            fieldId: 'role',
            value: data.receiverRole
          }
        ];

        const hiddenQuestions = [];
        const conditions = (negotiation?.contractType as ContractType)
          ?.conditions;
        for (const step of (negotiation?.contractType as ContractType)?.form
          ?.steps) {
          for (const subStep of step?.subSteps) {
            const subStepCondition = conditions.subSteps?.find(
              (c: any) => c[subStep.id] !== undefined
            );
            if (
              subStepCondition != null &&
              !parseConditions(subStepCondition, parseAnswers(answers))
            ) {
              continue;
            }
            for (const question of subStep.questions) {
              const questionCondition = conditions.questions?.find(
                (c: any) => c[question.id] !== undefined
              );
              if (!question.hidden) {
                continue;
              }
              const conditionForBeingHidden =
                question?.pathWithDefaultValue?.condition;
              if (
                conditionForBeingHidden != null &&
                !parseConditions(conditionForBeingHidden, parseAnswers(answers))
              ) {
                continue;
              }
              if (
                questionCondition != null &&
                !parseConditions(questionCondition, parseAnswers(answers))
              ) {
                continue;
              }
              hiddenQuestions.push({
                ...question,
                stepId: step.id,
                subStepId: subStep.id
              });
            }
          }
        }

        for (const hiddenQuestion of hiddenQuestions) {
          const value = _.get(
            { user },
            hiddenQuestion?.pathWithDefaultValue.value
          );
          answers.push({
            stepId: hiddenQuestion.stepId,
            subStepId: hiddenQuestion.subStepId,
            fieldId: hiddenQuestion.field.id,
            questionId: hiddenQuestion.id,
            value
          });
        }

        setNegotiation({
          ...negotiation,
          latestReceiverResponses: {
            date: new Date().toString(),
            answers
          },
          receiverResponses: [
            {
              date: new Date().toString(),
              answers
            }
          ]
        });
        setIsFirstCounter(true);
        setFirstCounterAnswers(answers);
        roleRetrieved.current = data.receiverRole;
      }
    };

    setRole();
    if (paymentParam === 'successful') {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('payment');
      setSearchParams(queryParams.toString());
      onOpenPaymentSuccessful();
    }
  }, [hasCounterParam, negotiation, user]);

  useEffect(() => {
    if (location.state?.showSentAlert) {
      if (process.env.STRIPE_FLOW_ENABLED) onOpenSent();
      else onOpenPaymentSuccessful();
    }
  }, [location.state?.showSentAlert, negotiation, onOpen]);

  useEffect(() => {
    const getNegotiation = async () => {
      const { data } = await getNegotiationById(id!);

      const { initiator, receiver, receiverRole } = data;
      const isUserInitiator = user!.email === initiator.email;

      let newReceiver: any = {};

      if (!isUserInitiator && !receiver) {
        newReceiver = {
          firstName: user!.firstName,
          lastName: user!.lastName,
          email: user!.email,
          role: receiverRole,
          profileImageUrl: ''
        };
        setNegotiation({ ...data, receiver: newReceiver });
      } else {
        setNegotiation({ ...data });
      }
    };

    try {
      setIsLoading(true);
      getNegotiation();
    } catch (error) {
      onOpenError();
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [id, onOpenError, setNegotiation, user]);

  useEffect(() => {
    setNavbarColor(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAnswers = async (answers: Answer[]) => {
    let cleanAnswers: Answer[] = answers.map(
      ({ stepId, subStepId, questionId, fieldId, value }) => ({
        stepId,
        subStepId,
        questionId,
        fieldId,
        value
      })
    ); //This is because Luke's API doesn't check that form of the answer object - need to fix that

    if (isFirstCounter) {
      cleanAnswers = [...cleanAnswers, ...firstCounterAnswers];
    }

    try {
      setIsLoading(true);
      const { data } = await updateNegotiation(negotiation!._id, {
        answers: cleanAnswers
      });
      setNegotiation(data);
      onOpen();
    } catch (err) {
      setIsLoading(false);
      onOpenError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewContract = async () => {
    try {
      setIsGeneratingContract(true);
      const { data } = await createContract(negotiation!._id);
      setIsGeneratingContract(false);
      navigate(`/contract/${data.contract._id}`);
    } catch (err) {
      setIsGeneratingContract(false);
      onOpenError();
    } finally {
      setIsGeneratingContract(false);
    }
  };

  const { isOpen: isOpenPaymentSuccessful, onClose: onClosePaymentSuccessful, onOpen: onOpenPaymentSuccessful } = useDisclosure();
  const { isOpen: isPaymentOpen, onClose: onClosePayment, onOpen: onOpenPayment } = useDisclosure();
  const [stripeUrl, setStripeUrl] = useState<string>('');
  const { showServerError } = useApiHelpers();

  const callStripePaymentLink = useCallback(
    async (negotiation: any) => {
      try {
        let data;
        const req = {
          successUrl: `${process.env.REACT_APP_STRIPE_URL}/negotiation/${negotiation!._id}?payment=successful`,
          cancelUrl: `${process.env.REACT_APP_STRIPE_URL}/negotiation/${negotiation!._id}`,
          negotiationId: negotiation!._id
        };
        const response = await getStripeUrl(req);
        data = response.data;
        setStripeUrl(data?.url);
      } catch (error) {
        console.log(error)
        showServerError();
      }
    },
    [onClosePayment, showServerError]
  );

  useEffect(() => {
    if (!!stripeUrl) openInCurrentTab(stripeUrl);
  }, [stripeUrl]);

  if (isGeneratingContract) {
    return <LoadScreen label="Generating contract..." />;
  }

  if (isLoading) {
    return <LoadScreen label="Loading..." />;
  }

  if (!negotiation) {
    return <></>;
  }

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      // bgImage="url(/studio.jpg)"
      // bgPosition="center"
      // bgSize="cover"
      bg={negotiationPanelTheme.background}
      overflowY={{ lg: 'hidden' }}
    >
      <Flex
        h="100%"
        alignItems="center"
        justifyContent="space-between"
        overflowY={{ base: 'auto', lg: 'hidden' }}
      >
        <NegotiationPanel
          negotiation={negotiation}
          isFirstCounter={isFirstCounter}
          onSubmitAnswers={handleSubmitAnswers}
          onViewContract={handleViewContract}
          onOpenPayment={onOpenPayment}
        />
      </Flex>
      <AlertModal
        isOpen={isOpen}
        onAccept={onClose}
        acceptText="Continue"
        title="Your offer has been sent!"
        variant="success"
      />
      <AlertModal
        isOpen={isOpenSent}
        onAccept={() => callStripePaymentLink(negotiation)}    
        onDecline={onCloseSent}
        acceptText="Continue"
        declineText="Cancel"
        title="Your offer was created!"
        text='Do you want to go to the payment now?'
        variant="info"
      />
      <AlertModal
        isOpen={isOpenError}
        onAccept={onCloseError}
        variant="warning"
        title="Something went wrong!"
        acceptText="OK"
      />
      <AlertModal
        isOpen={isPaymentOpen}
        onAccept={() => callStripePaymentLink(negotiation)}
        onDecline={onClosePayment}
        acceptText="Continue"
        declineText="Cancel"
        title="You will be redirected to the payment page to proceed with the offer."
        variant="info"
      />
      <AlertModal
        isOpen={isOpenPaymentSuccessful}
        onAccept={onClosePaymentSuccessful}
        variant="success"
        title="Your offer has been sent to the recepient!"
        acceptText="OK"
      />
    </Flex>
  );
};
